import {forEach, findKey} from "lodash";
import {stateMerge} from "../object-merge";

const getDefaultState = () => {
    return {    }
};

const state = getDefaultState();

const getters = {
    getDefaultFilter: (state, getters) => {
        return state.filters.predefined[getters.getDefaultFilterKey];
    },
    getDefaultFilterKey: (state) => {
        return findKey(state.filters.predefined, { default: true });
    }
};

const actions = {
    async set({commit}, settings) {
        commit("MERGE_STATE", settings)
    },
};

const mutations = {
    MERGE_STATE(state, settings) {
        forEach(settings, (data, key) => {
            stateMerge(state, data, key, false, true)
        });
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
