<template>
    <v-layout fill-height column class="event-list fill-sidebar">
        <div class="grey darken-3 d-flex flex-grow-0 justify-center">
            <v-text-field v-model="search"
                          flat
                          hide-details
                          prepend-inner-icon="search"
                          placeholder="..."
                          solo
                          dark
                          background-color="grey darken-3"
                          dense
                          clearable
            >
            </v-text-field>
            <v-select dark
                      dense
                      solo
                      background-color="grey darken-2"
                      rounded
                      flat
                      hide-details
                      v-model="filter"
                      :items="filterItems"
                      class="dense-xs mt-1 ml-1"
            >
            </v-select>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-switch
                                class="ml-4 mt-1 mb-1 pr-5"
                                inset
                                dark
                                hide-details
                                color="red"
                                v-model="onlyActive"
                                label="Kal"
                        ></v-switch>
                    </div>
                </template>
                Skryje neuzavřené události na kterých již neprobíhá činnost
            </v-tooltip>
        </div>
        <v-list class=" d-flex flex-column overflow-auto">
            <template v-for="(event) in events">
                <event-head :key="event.uuid" @onSelect="setActiveEvent(event.uuid)"
                            v-bind:eventKey="event.uuid"></event-head>
            </template>
        </v-list>
    </v-layout>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import eventHead from "./event/Head";
import {isEmpty, map, findKey} from "lodash";

export default {
    name: 'events',
    components: {
        eventHead,
    },
    props: {
        events: {
            type: Array,
        },
    },
    methods: {
        ...mapActions('events', [
            'setActiveEvent',
        ]),
    },
    data() {
        return {}
    },
    computed: {
        ...mapState({
            predefinedFilter: state => state.settings.filters.predefined,
            isMobile: state => state.isMobile,
        }),
        ...mapGetters('settings', {
            defaultFilterKey: 'getDefaultFilterKey',
        }),
        filterItems() {
            return map(this.predefinedFilter, (value, key) => ({text: value.name, value: key}));
        },
        defaultFilterKey() {
            return findKey(this.predefinedFilter, { default: true });
        },
        filter: {
            get() {
                return this.defaultFilterKey;
            },
            set(value) {
                let filter = JSON.parse(JSON.stringify(this.predefinedFilter[value].filter));
                if (isEmpty(filter))
                    filter = {};

                if (this.search)
                    filter.search = this.search;

                this.$store.commit('events/SET_FILTERS', filter)
            }
        },
        search: {
            get() {
                return this.$store.state.events.filters?.search;
            },
            set(value) {
                this.$store.commit('events/SET_SEARCH', value)
            }
        },
        onlyActive: {
            get() {
                return this.$store.state.events.onlyActive;
            },
            set(value) {
                this.$store.commit('events/setOnlyActive', value)
            }
        },
    },
}
</script>
