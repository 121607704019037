import Vue from 'vue'
import moment from "moment/moment";
import {
    defaults,
    findKey,
    forEach,
    includes,
    isEmpty,
    isObject,
    keys,
    minBy,
    orderBy,
    pick,
    pickBy,
    size,
    toArray,
    intersection,
    values,
    map,
    get,
    chain,
    deburr
} from "lodash";
import {uuid} from 'vue-uuid';
import eventsApi from '../../api/events'
import addressApi from '../../api/address'
import stopaApi from '../../api/stopa'
import {appBus} from "@/main";
import {stateMerge} from "../object-merge";
import {getDistance} from 'ol/sphere';
import {ON_ACTIVE_EVENT_BLOCK, ON_EVENT_INSERT} from "@/events";

const NEAREST_EVENTS_SEARCH_RADIUS = 5000;
const EVENT_STATE_ASSIGNED_ID = 4;

const hiddenCategories = [
    9,
    12,
];

const inactiveStates = [
    3,
    EVENT_STATE_ASSIGNED_ID,
    5,
    6,
    7,
];
const closedStates = [
    3,
    EVENT_STATE_ASSIGNED_ID,
    5,
    7,
];
const deletedStates = [
    6,
];

const fillAttributes = [
    'id',
    'uid',
    'parentId',
    'eventStateId',
    'eventSourceId',
    'eventCategoryId',
    'eventTerrainId',
    'eventStatusId',
    'eventTypeId',
    'serviceId',
    'deviceId',
    'eventToBase',
    'eventAccess',
    'uuid',
    'baseId',
    'code',
    'header',
    'alert',
    'data',
    'subject',
    'description',
    'destinationLatitude',
    'destinationLongitude',
    'targetLatitude',
    'targetLongitude',
    'senderName',
    'eventTypeId',
    'reporterName',
    'reporterNumber',
    'reporterDescription',
    'investigator',
    'manager',
    'address',
    'addressData',
    'addressDescription',
    'start',
    'end',
    'createdAt',
    'insertedAt',
    'startAt',
    'endAt',
    'startedAt',
    'endedAt',
    'locations',
    'messages',
    'eventToTags',
];
const updateAttributes = [
    'uuid',
    'parentId',
    'eventStateId',
    'eventStatusId',
    'eventTypeId',
    'eventSourceId',
    'eventCategoryId',
    'eventTerrainId',
    'serviceId',
    'deviceId',
    'eventToBase',
    'eventAccess',
    'code',
    'subject',
    'description',
    'reporterName',
    'reporterNumber',
    'reporterDescription',
    'address',
    'addressData',
    'addressDescription',
    'startAt',
    'endAt',
    'startedAt',
    'endedAt',
    'targetLatitude',
    'targetLongitude',
    'destinationLatitude',
    'destinationLongitude',
    'investigator',
    'manager',
];

const readonlyTags = [
    'ISPO',
    'ISPO_QUEUE'
];

const getDefaultState = () => {
    return {
        local: {},
        remote: {},
        readonlyInputs: [
            'eventTypeId',
            'reporterNumber',
            'reporterName',
            'reporterDescription',
            'address',
            'addressDescription',
            'description',
            'serviceId',
        ],
        filter: null,
        filters: {},
        active: false,
        onlyActive: false,
    }
};
const state = getDefaultState();

const getters = {
    isActiveEvent: state => {
        return state.active !== false;
    },
    isActiveByKey: state => {
        return (key) => {
            return state.active === key;
        }
    },
    isActiveEventChanged: (state, getters) => {
        if (state.active)
            return getters.isEventChanged(state.active);

        return false;
    },
    isEventChanged: state => key => {
        return Object.prototype.hasOwnProperty.call(state.remote, key);
    },
    extentEvent: (state, getters, rootState, rootGetters) => (key) => {

        let event = state.local[key];
        let extent = [];
        if (event.targetLongitude && event.targetLatitude)
            extent.push([event.targetLongitude, event.targetLatitude]);

        if (event.destinationLongitude && event.destinationLatitude)
            extent.push([event.destinationLongitude, event.destinationLatitude]);

        let jobsExtend = rootGetters['eventJobs/extent'](key);
        if (jobsExtend)
            extent = extent.concat(jobsExtend);

        if (state.active === key) {
            let eventObjectExtend = rootGetters['eventPoises/extent'](key);
            if (eventObjectExtend)
                extent = extent.concat(eventObjectExtend);

            let geoExtend = rootGetters['geo/extent'](key);
            if (geoExtend)
                extent = extent.concat(geoExtend);

            let messageExtend = rootGetters['messages/extend'](key);
            if (messageExtend)
                extent = extent.concat(messageExtend);

            if (!isEmpty(rootState.map.extraExtents)) {
                forEach(rootState.map.extraExtents, (values) => {
                    extent = extent.concat(values);
                });
            }
        }
        return extent;
    },
    isCreated: state => {
        return (key) => {
            return state.local[key].id > 0;
        }
    },
    canEdit: (state, getters) => {
        return getters.isActive(state.active)
    },
    isActive: state => (event_key) => {
        if (state.local[event_key] !== undefined)
            return !includes(inactiveStates, state.local[event_key].eventStateId)
        return false;
    },
    isClosed: () => (event) => {
        return includes(closedStates, event.eventStateId) && !includes(deletedStates, event.eventStateId)
    },
    isDeleted: () => (event) => {
        return includes(deletedStates, event.eventStateId)
    },
    isLocal: state => {
        return (key) => {
            return state.local[key].id === null;
        }
    },
    getEvents: state => {
        return state.local;
    },
    getRecentEvents: (state, getters, rootState, rootGetters) => {
        return pickBy(state.local, function (item) {

            if (includes(inactiveStates, item.eventStateId))
                return false;

            if (includes(hiddenCategories, item.eventCategoryId))
                return false;

            if (state.onlyActive) {
                let jobs = rootGetters['eventJobs/jobs'](item.uuid);
                let activeJobs = pickBy(jobs, function (job) {
                    return !(job.eventJobStatusId === 19 || job.eventJobStatusId === 20);
                });
                if (size(activeJobs) === 0 && (item.eventStateId !== 1 && item.eventStateId !== 2 && item.eventStateId !== 10 && item.eventStateId !== 11))
                    return false;
            }

            return (
                (item.startAt && moment(item.startAt).isSameOrBefore(moment(), 'day'))
                ||
                (item.startedAt && moment(item.startedAt).isSameOrBefore(moment(), 'day'))
            );
        });
    },
    getFilteredEvents: (state, getters, rootState, rootGetter) => {
        let recentEvents = getters.getRecentEvents;

        return orderBy(
            pickBy(recentEvents, function (item) {

                let passed = true;

                forEach(state.filters, (filter, key) => {
                    if (isEmpty(filter)) {
                        return;
                    }

                    const ids = filter;
                    let value = null;

                    switch (key) {
                        case 'eventCategoryTypes':
                            if (!includes(ids, rootGetter['eventCategories/getEventCategoryById'](item.eventCategoryId).type))
                                passed = false;
                            break;
                        case 'eventCategories':
                            if (!includes(ids, item.eventCategoryId))
                                passed = false;
                            break;
                        case 'sections':
                            if (intersection(ids, values(item.eventToSection)).length === 0)
                                passed = false;
                            break;
                        case 'bases':
                            if (intersection(ids, values(item.eventToBase)).length === 0)
                                passed = false;
                            break;
                        // case 'groups':
                        //     console.log('GROUPS', ids, values(item.eventToTag))
                        //     if (intersection(ids, values(item.eventToGroup)).length === 0)
                        //         passed = false;
                        //     break;
                        case 'tags':
                            if (intersection(ids, map(item.eventToTags, (value, key) => +key)).length === 0)
                                passed = false;
                            break;

                        case 'data':
                            value = get(item, 'data.spravce');
                            if (!value) {
                                passed = false;
                            } else {
                                if (!includes(ids, value.toLowerCase()))
                                    passed = false;
                            }
                            break;

                        case 'search':
                            if (!chain(item.header)
                                .map('value')
                                .map(deburr)
                                .filter(Boolean)
                                .join(' ')
                                .value()
                                .toLowerCase()
                                .includes(deburr(ids.toLowerCase())))
                                passed = false;
                            break;
                    }
                })
                return passed;
            }),
            ['start'], ['desc']
        );
    },
    getEventTargetCoordinates: () => (event) => {
        return event.targetLongitude && event.targetLatitude ? [parseFloat(event.targetLongitude), parseFloat(event.targetLatitude)] : false;
    },
    getNearestEvents: (state, getters, rootState, rootGetters) => (sourceEvent) => {
        const sourceEventTargetCoordinates = getters.getEventTargetCoordinates(sourceEvent);
        if (sourceEventTargetCoordinates) {
            return pickBy(getters.getEvents, (event) => {

                if (event.uuid === sourceEvent.uuid)
                    return false;

                if (includes(inactiveStates, event.eventStateId))
                    return false;

                const eventCategory = rootGetters['eventCategories/getEventCategoryById'](event.eventCategoryId);
                if (eventCategory.type !== 'event')
                    return false;

                let targetCoordinates = getters.getEventTargetCoordinates(event);
                if (targetCoordinates) {
                    let distance = Math.round(getDistance(sourceEventTargetCoordinates, targetCoordinates));
                    if (distance <= NEAREST_EVENTS_SEARCH_RADIUS / 2) {
                        event.distance = distance;
                        return true;
                    }
                }
            });
        }
        return false;
    },
    getNearestEvent: (state, getters) => (sourceEvent) => {
        return minBy(toArray(getters.getNearestEvents(sourceEvent)), function (o) {
            return o.distance;
        });
    },
    getEventTags: (state, getters, rootState) => (event) => {
        if (event !== undefined) {
            return pick(rootState.tags.items, keys(event.eventToTags));
        }
        return {}
    },
    getChildEvents: (state) => (id) => {
        return orderBy(pickBy(state.local, function (item) {
            return item.parentId === id
        }), ['start'], ['desc']);
    },
    getLocalEvents: (state) => {
        return pickBy(state.local, function (item) {
            return item.id === null;
        });
    },
    getActiveEvent: state => {
        return state.local[state.active];
    },
    getActiveEventId: state => {
        return state.active;
    },
    getEventId: state => {
        return (key) => {
            return state.local[key].id;
        }
    },
    byId: (state, getters) => (id) => {
        let key = findKey(state.local, function (o) {
            return o.id === id;
        });
        let item = getters['getByKey'](key);
        if (item !== undefined)
            return item;
        return null;
    },
    getByKey: (state) => (key) => {
        if (state.local[key] !== undefined)
            return state.local[key];

        return null;
    },
    canEditAttribute: (state, getters, rootState, rootGetters) => (event_key, attribute) => {
        let event = state.local[event_key];

        if (event === undefined)
            return false

        let eventCategory = rootGetters['eventCategories/getEventCategoryById'](event.eventCategoryId);

        if (eventCategory === undefined)
            return false

        if (attribute === 'eventToBase')
            return true

        if (event.baseId !== rootState.auth.user.baseId)
            return false;

        if (includes(inactiveStates, event.eventStateId)) {
            return false;
        }

        if (eventCategory.inputs !== null && Object.prototype.hasOwnProperty.call(eventCategory.inputs, attribute)) {
            let inputSettings = eventCategory.inputs[attribute];
            if (inputSettings.readonly) {
                return false;
            } else if (includes(state.readonlyInputs, attribute) && isObject(event.eventToTags)) {
                let canEdit = true;
                let tags = event.eventToTags;
                forEach(readonlyTags, function (value) {
                    if (includes(tags, value)) {
                        canEdit = false;
                        return false;
                    }
                });
                return canEdit;
            }
            return true;
        }
        return false;
    },
    hasTags: (state) => (eventKey, tags) => {
        let event = state.local[eventKey];
        let hasTags = false;
        if (isObject(event.eventToTags)) {
            forEach(tags, function (value) {
                if (includes(event.eventToTags, value)) {
                    hasTags = true;
                    return false;
                }
            });
        }
        return hasTags;
    },
    destinationCoordinates: (state) => (eventKey) => {
        let event = state.local[eventKey];
        if (event.destinationLatitude && event.destinationLongitude) {
            return [event.destinationLongitude, event.destinationLatitude]
        } else {
            return [event.targetLongitude, event.targetLatitude]
        }
    },
    targetTooltip: (state, getters, rootState, rootGetters) => (event) => {
        return {
            title: rootGetters['eventCategories/getEventCategoryById'](event.eventCategoryId).name,
            content: getters['tooltipContent'](event),
            overview: event.header.overview.value,
        };
    },
    tooltipContent: () => (event) => {
        if (!event)
            return '';
        return moment(event.createdAt).format('HH:mm DD.MM.YYYY') + '<br />' +
            (event.header.title !== undefined && event.header.title.value ? '<div class="' + event.header.title.class + '">' + event.header.title.value + '</div>' : '') +
            (event.header.subtitle !== undefined && event.header.subtitle.value ? '<div class="text-truncate ' + event.header.subtitle.class + '">' + event.header.subtitle.value + '</div>' : '') +
            (event.header.description !== undefined && event.header.description.value ? '<div class="text-truncate ' + event.header.description.class + '">' + event.header.description.value + '</div>' : '');
    },
};

const actions = {
    async set({commit, dispatch, state}, payload) {

        let events = payload.events;
        let resetData = payload.resetData;

        if (events === undefined)
            return;

        if (events === '_RK_') {
            dispatch('setActiveEvent', false);
            forEach(state.local, (value, uuid) => {
                dispatch('notifications/clearEventDialogs', uuid, {root: true});
            });
            commit('UPDATE_REMOTE', {});
        } else {

            let newEvents = {};
            forEach(events, function (value, uuid) {
                if (resetData !== true && value?.id > 0 || value === '_RK_') {
                    if (state.local[uuid] === undefined) {
                        newEvents[uuid] = value;
                    } else {
                        let event = state.local[uuid];
                        if (value === '_RK_') {
                            if (state.active === uuid)
                                dispatch('setActiveEvent', false);

                            dispatch('notifications/clearEventDialogs', uuid, {root: true});
                        }

                        if (value.eventStateId !== undefined) {
                            let active = !includes(inactiveStates, value.eventStateId);
                            if (active === false && state.active === event.uuid)
                                dispatch('setActiveEvent', false);

                            if (active === true && !includes(inactiveStates, state.local[uuid].eventStateId) === false) {
                                newEvents[uuid] = value;
                            }
                        }
                    }
                }
            });

            commit('UPDATE_REMOTE', events);

            forEach(newEvents, function (value, uuid) {
                if (value !== undefined || value !== '_RK_') {
                    window.console.log('NEW EVENT FOUND', uuid, value);
                    appBus.$emit(ON_EVENT_INSERT, uuid);
                }
            });
        }
    },

    setDefaultEventFilter({rootGetters, commit}) {
        const defaultFilter = rootGetters['settings/getDefaultFilter'];

        if (Array.isArray(defaultFilter?.filter))
            defaultFilter.filter = {};

        commit('SET_FILTER', defaultFilter);
        commit('SET_FILTERS', defaultFilter?.filter);
    },

    setAttribute({dispatch}, payload) {
        return dispatch('update', payload);
    },

    /**
     * Set event to editable
     * Backup and Update event attribute
     * @param commit
     * @param dispatch
     * @param payload
     */
    updateAttributes({commit}, payload) {
        commit('UPDATE_LOCAL_ATTRIBUTES', payload);
    },
    updateRemote({commit}, payload) {
        commit('UPDATE_REMOTE', payload);
    },
    async updateLocalAttributes({commit}, payload) {
        commit('UPDATE_LOCAL_ATTRIBUTES', payload);
    },
    setActiveState({commit}, stateId) {
        commit('setState', stateId)
    },
    setActiveEvent({state, commit, dispatch}, key) {
        if (state.active !== key) {
            commit('map/clearExtraExtents', null, {root: true});

            if (state.local[key] === undefined)
                commit('setActive', false);

            commit('setActive', key);
            dispatch('map/setAutoFocus', true, {root: true});
        }
        return dispatch('extendToActiveEvent');
    },
    setActiveCoordinates({commit}, coordinates) {
        commit('setActiveCoordinates', coordinates)
    },
    setDestination(context, uuid) {
        appBus.$emit(ON_ACTIVE_EVENT_BLOCK, uuid);
    },
    /**
     *
     * @param dispatch
     * @param payload
     */
    createFrom({dispatch}, payload) {
        return new Promise((resolve, reject) => {
            let data = pick(payload.event, updateAttributes);
            eventsApi.createFrom(payload.event.uuid, data, payload.sendToQueue).then((result) => {
                // window.console.log(result);

                dispatch('updateLocalAttributes', {
                    key: payload.event.uuid,
                    item: {eventToTags: {2: 'ISPO_QUEUE'}}
                });
                dispatch('afterSave', {response: result.call, payload: payload});
                dispatch('set', {events: {[result.event.uuid]: result.event}}).then(() => {
                    // commit('setActive', result.event.uuid);
                    resolve();
                });
                dispatch('notifications/add', {
                    type: 'success',
                    message: result.message
                }, {root: true});

                if (payload.sendToQueue)
                    dispatch('sendToSystem', result.event.uuid);

            }).catch(function (error) {
                dispatch('notifications/add', {
                    type: 'error',
                    message: "Chyba při tvorbě udádolsti: " + error
                }, {root: true});
                reject();
            });
        });
    },

    /**
     *
     * @param dispatch
     * @param payload
     */
    async save({dispatch}, payload) {
        return new Promise((resolve) => {
            let event = payload.event;
            if (event.id !== undefined && event.id) {
                dispatch('update', {key: event.uuid, item: event}).then((response) => {
                    dispatch('afterSave', {response: response, payload: payload});
                    resolve(response);
                });
            } else {
                dispatch('insert', payload).then((response) => {
                    dispatch('afterSave', {response: response, payload: payload});
                    resolve(response);
                });
            }
        });
    },

    update(context, payload) {
        return new Promise((resolve) => {
            let data = pick(payload.item, updateAttributes);
            return eventsApi.updateEvent(payload.item.id, data).then((response) => {
                resolve(response);
            });
        });
    },

    /**
     *
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {Promise<any>}
     */
    insert({commit, dispatch}, payload) {
        return new Promise((resolve) => {
            let data = pick(payload.event, updateAttributes);
            eventsApi.createEvent(data).then((response) => {
                if (response.id) {
                    dispatch('notifications/add', {
                        type: 'success',
                        message: 'Událost byla založena'
                    }, {root: true});
                    commit('setId', {key: response.uuid, id: response.id});
                    // commit('setEdit', {key: response.uuid, value: false});
                    resolve(response);
                } else {
                    dispatch('notifications/add', {
                        type: 'error',
                        message: "Chyba při ukládání události: " + response
                    }, {root: true});
                }
            });
        })
    },

    /**
     *
     */
    afterSave({commit}, payload) {
        // window.console.log('UPDATE', payload);
        commit('update', {key: payload.response.uuid, item: payload.response});
    },

    /**
     *
     * @param commit
     * @param dispatch
     * @param state
     * @param key
     */
    sendToSystem({dispatch}, key) {

        dispatch('notifications/add', {
            type: 'info',
            message: 'Odesílám událost do Spojaře'
        }, {root: true});

        stopaApi.sendToQueue(key).then((response) => {
            dispatch('notifications/add', {
                type: 'success',
                message: response.msg
            }, {root: true});
        }).catch(function (error) {
            dispatch('notifications/add', {
                type: 'error',
                message: "Chyba při ukládání události do fronty Spojaře: " + error
            }, {root: true});
        });
    },
    /**
     *
     * @param dispatch
     * @param code
     */
    createTestCall({dispatch}, code) {
        return stopaApi.get('/kopis/test/call/?code=' + code).then((response) => {
            dispatch('notifications/add', {
                type: 'success',
                message: response.msg
            }, {root: true});
        }).catch(function (error) {
            dispatch('notifications/add', {
                type: 'error',
                message: "Chyba při vytváření Test KOPIS zprávy: " + error
            }, {root: true});
        });
    },

    /**
     *
     * @param dispatch
     * @param key
     * @returns {*}
     */
    end({dispatch}, key) {
        return dispatch('update', {key: key, item: {eventStatusId: 6}}).then(() => {
            dispatch('notifications/add', {
                type: 'success',
                message: "Událost odebrána"
            }, {root: true});
        })
    },

    /**
     *
     * @param commit
     * @param state
     * @param key
     */
    cancel({commit, state}, key) {
        commit('UPDATE_LOCAL_ATTRIBUTES', {key: key, item: state.remote[key], clearRemote: true});
    },

    async newEvent({commit, dispatch, rootState}, payload) {

        dispatch('panel/showSidebar', null, {root: true});

        let timestamp = ~~(Date.now());
        let uuid5Name = rootState.auth.user.id.toString() + '-' + rootState.auth.device.id.toString() + ' - ' + timestamp.toString();
        let key = uuid.v5(uuid5Name, rootState.setup.setup.uuid);
        let event = await dispatch('getEventDefaults', {key: key, eventCategoryId: payload.eventCategoryId});

        appBus.$emit('clearAddressSearch');
        commit('new', {key: key, item: event});
    },

    async getEventDefaults({rootState, rootGetters}, payload) {

        let eventCategoryId = 5

        if (payload.eventCategoryId !== undefined)
            eventCategoryId = payload.eventCategoryId;

        let eventCategory = rootGetters['eventCategories/getEventCategoryById'](eventCategoryId);
        let event = {
            id: null,
            parentId: null,
            uuid: payload.key,
            address: null,
            addressData: {},
            addressDescription: null,
            reporterName: null,
            reporterNumber: null,
            reporterDescription: null,
            baseId: rootState.auth.user.baseId,
            deviceId: rootState.auth.device.id,
            code: null,
            header: {
                title: {
                    value: eventCategory.name,
                    class: "font-weight-bold",
                },
                subtitle: {
                    value: "neodesláno",
                    class: null,
                },
                description: {
                    value: null,
                    class: null,
                },
                overview: {
                    value: null,
                    class: null,
                },
            },
            alert: {
                title: {
                    value: null,
                    class: null,
                },
                subtitle: {
                    value: null,
                    class: null,
                },
                description: {
                    value: null,
                    class: null,
                },
            },
            data: null,
            eventToBase: {},
            eventAcccess: {},
            createdAt: moment(),
            subject: "",
            description: "",
            overview: null,
            detail: null,
            destinationLatitude: null,
            destinationLongitude: null,
            eventCategoryId: eventCategory.id,
            eventTerrainId: 1,
            eventSourceId: 2,
            eventStateId: 1,
            eventStatusId: 2,
            eventTypeId: 2,
            serviceId: null,
            insertedAt: null,
            startAt: null,
            endAt: null,
            startedAt: ~~(Date.now() / 1000),
            endedAt: null,
            targetLatitude: null,
            targetLongitude: null,
            start: null,
            end: null,
            investigator: null,
            manager: null,
            jobs: [],
            messages: [],
            locations: [],
        };

        // set defaults
        forEach(eventCategory.defaultValues, (value, attribute) => {
            if (event[attribute] !== undefined)
                event[attribute] = value;
        });

        event.eventToBase[rootState.auth.user.baseId] = rootState.auth.user.baseId;

        return event;
    },

    remove({commit, state}) {
        commit('remove', state.active);
        commit('setActive', false);
    },

    /**
     *
     * @param dispatch
     * @param getters
     * @param rootState
     * @param rootGetters
     * @param uuid
     * @returns {Promise<void>}
     */
    async setPointAsEventTarget({dispatch, getters, rootState, rootGetters}, uuid) {

        dispatch('panel/hideMapSearch', null, {root: true});

        let event = getters.getActiveEvent;
        let point = rootState.map.points.find(item => item.uuid === uuid);
        let eventTerrainId = point.type_code === 'custom' ? event.eventTerrainId : rootGetters['setup/getTerrainIdByCode'](point.type_code);

        point.coordinates[0] = parseFloat(point.coordinates[0].toFixed(8));
        point.coordinates[1] = parseFloat(point.coordinates[1].toFixed(8));

        let payload = {
            key: event.uuid,
            item: {
                targetLongitude: point.coordinates[0],
                targetLatitude: point.coordinates[1],
                eventTerrainId: eventTerrainId,
                address: 'Načítám...' + point.title,
                addressData: null,
            }
        };
        await dispatch('updateLocalAttributes', payload);
        dispatch('setEventTarget', {coordinates: point.coordinates, event: event});
    },

    /**
     * Set event target from reverse geo
     * @param dispatch
     * @param getters
     * @param coordinates
     */
    async setCoordinatesAsEventTarget({dispatch, getters}, coordinates) {

        let event = getters.getActiveEvent;

        coordinates[0] = parseFloat(coordinates[0].toFixed(8));
        coordinates[1] = parseFloat(coordinates[1].toFixed(8));

        let payload = {
            key: event.uuid,
            item: {
                targetLongitude: coordinates[0],
                targetLatitude: coordinates[1],
                address: 'Načítám...',
                addressData: null,
            }
        };

        // window.console.log(payload);

        await dispatch('updateLocalAttributes', payload);
        dispatch('setEventTarget', {coordinates: coordinates, event: event});
    },

    /**
     * Set event target from reverse geo
     * @param dispatch
     * @param getters
     * @param state
     * @param coordinates
     */
    async setCoordinatesAsEventDestination({dispatch, getters}, coordinates) {

        let event = getters.getActiveEvent;

        coordinates[0] = parseFloat(coordinates[0].toFixed(8));
        coordinates[1] = parseFloat(coordinates[1].toFixed(8));

        let payload = {
            key: event.uuid,
            item: {
                destinationLongitude: coordinates[0],
                destinationLatitude: coordinates[1],
            }
        };
        await dispatch('updateLocalAttributes', payload);
        dispatch('setEventTarget', {coordinates: coordinates, event: event});
    },


    async setEventTarget({dispatch}, payload) {

        dispatch('panel/hideMapSearch', null, {root: true});

        let event = payload.event
        // let eventCategory = rootGetters['eventCategories/getEventCategoryById'](event.eventCategoryId);
        let coordinates = payload.coordinates
        let response = await addressApi.reverse(coordinates, event, false);
        let eventToBase = {};
        let base = await dispatch('bases/getBaseByUid', response.address.jpo_id, {root: true});

        forEach(event.eventToBase, (base, baseId) => {
            eventToBase[baseId] = '_RK_'
        });

        eventToBase[base.id] = base.id;

        let data = {
            eventToBase: eventToBase,
            address: response.address.title,
            addressData: response.address,
        };

        dispatch('updateLocalAttributes', {
            key: event.uuid,
            item: data,
        });
    },

    /**
     *
     * @param state
     * @param dispatch
     * @param coordinates
     */
    extendToActiveEvent: ({state, getters, dispatch}) => {
        if (state.active)
            dispatch('map/extendToArray', getters.extentEvent(state.active), {root: true});
    },

    setFilter: ({commit}, value) => {
        commit("SET_FILTER", value);
    },
};

const mutations = {
    /**
     * Create new event
     * @param state
     * @param payload
     */
    new(state, payload) {
        Vue.set(state.local, payload.key, pick(payload.item, fillAttributes));
        state.active = payload.key;
    },
    /**
     * Add new event
     * @param state
     * @param payload
     */
    add(state, payload) {
        let attributes = pick(payload.item, fillAttributes);
        Vue.set(state.local, payload.key, attributes);
    },

    /**
     * Update event attributes
     * @param state
     * @param payload
     */
    update(state, payload) {
        let data = {};
        data[payload.key] = payload.item;
        stateMerge(state, data, 'local', false, true, state, 'remote');
        Vue.delete(state.remote, payload.key);
    },

    UPDATE_REMOTE(state, payload) {
        // window.console.log("EVENT UPDATE REMOTE", payload);
        stateMerge(state, payload, 'local', false, true, state, 'remote')
    },

    UPDATE_REMOTE_ATTRIBUTES(state, payload) {
        // window.console.log("EVENT UPDATE REMOTE ATTRIBUTES", payload);
        let data = {};
        data[payload.key] = payload.item;
        stateMerge(state, data, 'local', false, true, state, 'remote')
    },

    UPDATE_LOCAL_ATTRIBUTES(state, payload) {
        // window.console.log("EVENT LOCAL MUTATION", item, payload);
        let data = {};
        data[payload.key] = payload.item;
        if (state.local[payload.key].id > 0) {
            if (payload.clearRemote) {
                stateMerge(state, data, 'local', false, true, state, null, false, true)
                Vue.delete(state.remote, payload.key);
            } else {
                stateMerge(state, data, 'local', false, true, state, 'remote', true)
            }
        } else {
            stateMerge(state, data, 'local', false, true, state)
        }
    },
    /**
     * Remove Event
     * @param state
     * @param key
     */
    remove(state, key) {
        if (state.local[key] !== undefined) {
            Vue.delete(state.local, key);
            if (state.active === key) {
                state.active = false;
            }
        }
        if (state.remote[key] !== undefined) {
            Vue.delete(state.remote, key);
        }
    },

    /**
     * Backup event attributes
     * @param state
     * @param payload
     */
    backup(state, payload) {
        let attributes = defaults(payload.item, state.remote[payload.key]);
        Vue.set(state.remote, payload.key, pick(attributes, updateAttributes));
    },
    setActive(state, event) {
        state.active = event;
    },
    setEventTarget(state, payload) {
        state.local[payload.key].targetLongitude = payload.coordinates[0];
        state.local[payload.key].targetLatitude = payload.coordinates[1];
        state.local[payload.key].address = payload.address;
    },
    setActiveCoordinates(state, coordinates) {
        state.local[state.active].targetLongitude = coordinates[0];
        state.local[state.active].targetLatitude = coordinates[1];
        state.local[state.active].address = 'Dodelat reverzni geokoding';
    },
    // addMessageId(state, payload) {
    //     if (state.local[payload.eventKey] !== undefined)
    //         state.local[payload.eventKey].messages.push(payload.messageKey);
    // },
    setId(state, payload) {
        state.local[payload.key].id = payload.id;
    },
    setTypeId(state, typeId) {
        state.local[state.active].eventTypeId = typeId;
    },
    setState(state, stateId) {
        state.active.state = stateId;
    },
    setOnlyActive(state, value) {
        state.onlyActive = value;
    },
    setDescription(state, content) {
        state.local[state.active].description = content;
    },
    setReporterName(state, content) {
        state.local[state.active].reporterName = content;
    },
    setReporterNumber(state, content) {
        state.local[state.active].reporterNumber = content;
    },
    appendDescription(state, content) {
        if (state.local[state.active].description === null || state.local[state.active].description === '') {
            state.local[state.active].description = content;
        } else {
            state.local[state.active].description += "\n" + content;
        }
    },
    SET_FILTER: (state, value) => {
        state.filter = value;
    },
    SET_FILTERS: (state, filters) => {
        Vue.set(state, 'filters', filters);
    },
    SET_SEARCH: (state, search) => {
        Vue.set(state.filters, 'search', search);
    },
    resetState(state, payload) {
        Object.assign(state, getDefaultState());
        if (payload)
            Object.assign(state, payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
