<template>
    <v-layout class="map">
        <v-flex style="position: relative">

            <div class="panel-map-search-wrapper d-flex flex-row">
                <map-toolbar></map-toolbar>
            </div>

            <position v-if="!isMobile" v-bind:mouseCoordinate="mouseCoordinate" v-bind:clickCoordinate="clickCoordinate"
                      :zoom="zoom"></position>

            <map-cards></map-cards>
            <map-dialogs></map-dialogs>

            <vl-map ref="map"
                    :load-tiles-while-animating="false"
                    :load-tiles-while-interacting="false"
                    :data-projection="dataProjection"
                    :default-controls="defaultControls"
                    @click="onClick($event)"
                    @dblclick="setAutoFocus(false)"
                    @pointermove="onMapPointerMove"
                    @mounted="onMapMounted"
                    @created="onMapCreated"
                    @contextmenu.stop.prevent.native="onContextMenu($event)">
                <vl-view ref="mapView"
                         :min-zoom=8
                         :max-zoom=21
                         :zoom.sync="zoom"
                         :center.sync="center"
                         :projection="viewProjection"
                         :resolutions="resolutions"
                         :constrainResolution="true"
                ></vl-view>
                <div v-if="mapInitialized">

                    <layers v-bind:map="map"></layers>
                    <layer-bases></layer-bases>
                    <layer-drone v-if="droneLayer"></layer-drone>
                    <muv-layer v-if="isMuvEnabled" :update-interval=60></muv-layer>
                    <jpo-layer v-if="!isMobile"></jpo-layer>
                    <measure v-if="!isMobile"></measure>
                    <train-location v-if="!isMobile" :is-realtime="true"></train-location>
                    <hunting-regions></hunting-regions>
                    <draw></draw>

                    <vl-layer-group :opacity="mapOpacity">
                        <vl-feature v-if="searchHoverPoint" :properties="{type: 'search'}">
                            <vl-geom-point :coordinates="searchHoverPoint"></vl-geom-point>
                            <vl-style>
                                <vl-style-icon src="https://img.icons8.com/color/32/000000/sort-down.png"
                                               :anchor="[0.5, 1]"></vl-style-icon>
                            </vl-style>
                        </vl-feature>

                        <vl-layer-group>
                            <layer-events :screenShotActive="screenShotActive"></layer-events>
                        </vl-layer-group>

                        <layer-points ref="layerPoints"></layer-points>
                        <layer-panorama v-if="isPanoramaEnabled"></layer-panorama>
                        <layer-cadastre v-if="isCadastreEnabled"></layer-cadastre>
                        <layer-rail-images v-if="isRailImagesEnabled"></layer-rail-images>
                        <layer-routes v-bind:routes="routes"></layer-routes>
                        <vl-layer-vector :zIndex="150">
                            <vl-source-vector ref="searchPoints">
                                <vl-feature v-for="point in searchPoints" :key="point.id"
                                            :properties="{type: 'search', tooltip: point.name }">
                                    <vl-geom-point :coordinates="[point.longitude, point.latitude]"></vl-geom-point>
                                </vl-feature>
                                <vl-style>
                                    <vl-style-icon src="https://img.icons8.com/color/32/000000/sort-down.png"
                                                   :anchor="[0.5, 0.7]"></vl-style-icon>
                                </vl-style>
                            </vl-source-vector>
                        </vl-layer-vector>

                        <vl-layer-vector :zIndex="10">
                            <vl-source-vector ref="searchPoints">
                                <vl-feature v-if="originObject" :properties="{type: 'origin'}" :zIndex="1">
                                    <vl-geom-point :coordinates="originObject.coordinates"></vl-geom-point>
                                    <vl-style>
                                        <vl-style-icon
                                                src="https://img.icons8.com/material/24/000000/center-direction.png"
                                                :anchor="[0.5, 0.5]"></vl-style-icon>
                                    </vl-style>
                                </vl-feature>
                            </vl-source-vector>
                        </vl-layer-vector>
                    </vl-layer-group>

                    <layer-sap></layer-sap>
                    <layer-explore v-if="exloreVisible"></layer-explore>

                    <vl-overlay id="tooltip" v-if="tooltip" :position="tooltip.position" positioning="bottom-left"
                                :offset="[10,10]"
                                style="position: absolute">
                        <template>
                            <tooltip-content :tooltip="tooltip"></tooltip-content>
                        </template>
                    </vl-overlay>
                </div>
            </vl-map>
            <map-context-menu lazy ref="mapMenu"/>
            <link-event-context-menu lazy ref="linkEventContextMenu"/>
        </v-flex>
        <panorama v-if="isPanoramaEnabled" class="flex xs8 grow"></panorama>
    </v-layout>
</template>
<script>
/* eslint-disable no-unused-vars */

import Vue from 'vue'
import {mapState, mapGetters, mapActions} from 'vuex'
import VueLayers from 'vuelayers'
import {findPointOnSurface} from 'vuelayers/dist/ol-ext'
import Layers from './layers/Layers'
import LayerBases from './layers/Bases'
import LayerDrone from './layers/Drone'
import LayerEvents from './layers/Events'
import LayerRoutes from './layers/Routes'
import LayerPanorama from './layers/Panorama'
import LayerCadastre from './layers/Cadastre'
import Position from './Position'
import LayerRailImages from './layers/RailImages'
import Panorama from './Panorama'
import Draw from './layers/Draw'
import LayerPoints from './layers/Points'
import LayerSap from '../sap/MapLayer'
import LayerExplore from '../explore/MapLayer'
import {transform} from 'ol/proj'
import {toStringXY} from 'ol/coordinate';
import proj4 from 'proj4/dist/proj4-src.js'
import 'vuelayers/dist/vuelayers.css'
import {boundingExtent} from 'ol/extent'
import MuvLayer from "./muvLayer/muvLayer";
import JpoLayer from "./jpoLayer/jpoLayer";
import TrainLocation from './trainLocation/MapLayer';
import HuntingRegions from './huntingRegions/MapLayer';

import {throttle, isEmpty} from 'lodash';

import MapToolbar from './Toolbar'
import MapCards from './cards/Cards'
import MapDialogs from './dialogs/Dialogs'
import {appBus} from "@/main";

import Measure from "./measure/Measure";

proj4.defs('EPSG:5514,EPSG:1623',
    '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 ' +
    '+alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel ' +
    '+towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 ' +
    '+pm=greenwich +units=m +no_defs');

proj4.defs('EPSG:5514,EPSG:5239',
    '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 ' +
    '+alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel ' +
    '+towgs84=572.213,85.334,461.94,-4.9732,-1.529,-5.2484,3.5378 ' +
    '+pm=greenwich +units=m +no_defs');

proj4.defs('EPSG:5514,EPSG:15965',
    '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 ' +
    '+alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel ' +
    '+towgs84=589,76,480,0,0,0,0' +
    '+pm=greenwich +units=m +no_defs');

proj4.defs('EPSG:5514,EPSG:4836',
    '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 ' +
    '+alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel ' +
    '+towgs84=485,169.5,483.8,7.786,4.398,4.103,0 ' +
    '+pm=greenwich +units=m +no_defs');

proj4.defs('EPSG:102067', '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +pm=greenwich +units=m +no_defs +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56');
proj4.defs('EPSG:5514',
    '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 ' +
    '+alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel ' +
    '+towgs84=485,169.5,483.8,7.786,4.398,4.103,0 ' +
    '+pm=greenwich +units=m +no_defs');

import {register} from 'ol/proj/proj4.js'
import {HIDE_TOOLTIP, ON_MAP_SCREEN_SHOT, ON_RESIZE, ON_SHOW_EVENT_LINK_DIALOG} from "@/events";
import decode from "../../helpers/polyline";
import TooltipContent from "@/components/ui/TooltipContent";
import MapContextMenu from "@/components/contextMenu/MapContextMenu.vue";
import LinkEventContextMenu from "@/components/linkEvent/ContextMenu.vue";

register(proj4);

Vue.use(VueLayers, {});

export default {
    name: 'map-component',
    components: {
        LinkEventContextMenu,
        MapContextMenu,
        TooltipContent,
        Panorama,
        Layers,
        Draw,
        LayerBases,
        LayerDrone,
        LayerEvents,
        LayerPoints,
        LayerRoutes,
        LayerPanorama,
        LayerCadastre,
        LayerRailImages,
        LayerSap,
        LayerExplore,
        MapToolbar,
        MapCards,
        MapDialogs,
        Position,
        MuvLayer,
        Measure,
        JpoLayer,
        TrainLocation,
        HuntingRegions,
    },
    created() {
        appBus.$on(ON_MAP_SCREEN_SHOT, (eventId) => {
            this.createScreenShot(eventId);
        });
        appBus.$on(HIDE_TOOLTIP, () => {
            this.tooltip = false;
        });
        appBus.$on('onSearchPoints', (points) => {
            this.searchPoints = points;
        });
        appBus.$on(ON_RESIZE, () => {
            this.refresh();
        });
    },
    data() {
        return {
            tooltip: false,
            popup: false,
            map: undefined,
            mapInitialized: false,
            defaultControls: {
                attribution: true,
                rotate: false,
                zoom: false,
            },
            searchPoints: [],
            geolocPosition: undefined,
            panelOpen: true,
            mapVisible: true,
            screenShotActive: false,
            clickCoordinate: false,
            mouseCoordinate: false,
            apiKey: 'ArbsA9NX-AZmebC6VyXAnDqjXk6mo2wGCmeYM8EwyDaxKfQhUYyk0jtx6hX5fpMn',
            imagerySet: 'CanvasGray',
            hoverFeatures: [],
        }
    },
    computed: {
        center: {
            get() {
                return this.$store.state.map.center
            },
            set(value) {
                this.$store.commit('map/setCenter', value)
            }
        },
        mousePosition() {
            return toStringXY(this.mouseCoordinate, 6);
        },
        clickPosition() {
            return toStringXY(this.clickCoordinate, 6);
        },
        zoom: {
            get() {
                return this.$store.state.map.zoom
            },
            set(value) {
                this.$store.commit('map/setZoom', value)
            }
        },
        ...mapGetters('events', [
            'isActiveEvent',
            'canEdit',
        ]),
        ...mapGetters('explore', {
            exloreVisible: 'visible',
        }),
        ...mapGetters('cadastre', {
            isCadastreEnabled: 'visible',
        }),
        ...mapGetters('panorama', {
            isPanoramaEnabled: 'isEnabled',
        }),
        ...mapGetters('railImages', {
            isRailImagesEnabled: 'visible',
        }),
        ...mapGetters('eventTypes', {
            getEventType: 'get',
        }),
        ...mapState({
            zoomTo: state => state.map.zoomTo,
            activeEvent: state => state.events.active,
            dataProjection: state => state.map.dataProjection,
            viewProjection: state => state.map.viewProjection,
            resolutions: state => state.map.resolutions,
            points: state => state.map.points,
            mapOpacity: state => state.map.opacity,
            events: state => state.events.local,
            extend: state => state.map.extend,
            isMuvEnabled: state => state.map.muvLayer,
            originObject: state => state.map.originObject,
            droneLayer: state => state.map.droneLayer,
            searchHoverPoint(state) {
                if (state.map.searchHoverPoint)
                    return [parseFloat(state.map.searchHoverPoint.longitude), parseFloat(state.map.searchHoverPoint.latitude)];
                return false;
            },
            routes(state) {
                let items = [];
                state.routes.items.forEach((item) => {
                    item.coordinates = decode(item.route);
                    item.label = item.durationText + ' / ' + item.distanceText;
                    items.push(item);
                });
                return items;
            },
            contextMenu: state => state.panel.contextMenu,
            isMobile: state => state.isMobile,
        }),
    },
    methods: {
        ...mapActions('events',
            [
                'setActiveEvent',
            ]
        ),
        ...mapActions('map', {
            setAutoFocus: 'setAutoFocus',
            onZoom: 'onZoom',
        }),
        pointOnSurface: findPointOnSurface,
        refresh() {
            this.$nextTick(() => {
                if (this.map) {
                    try {
                        this.map.updateSize();
                    } catch (err) {
                        window.console.log(err);
                    }
                }
            });
        },
        async createScreenShot(eventId) {

            const map = this.map.$map;
            appBus.$emit('onScreenshotStart');

            this.map.$map.once('rendercomplete', () => {

                let clientImageDataUrl = this.getMapCanvas(map).toDataURL('image/jpeg');
                this.screenShotActive = true;

                this.$nextTick(() => {

                    const mapSize = this.map.$map.getSize();
                    const w = mapSize[0];
                    const h = mapSize[1];
                    const tl = transform(this.$refs.map.$map.getCoordinateFromPixel([0, 0]), this.viewProjection, this.dataProjection);
                    const br = transform(this.$refs.map.$map.getCoordinateFromPixel([w, h]), this.viewProjection, this.dataProjection);
                    const extent = [
                        tl[0],
                        br[1],
                        br[0],
                        tl[1]
                    ];

                    this.map.$map.once('rendercomplete', () => {
                        let mapCanvas = this.getMapCanvas(map);
                        mapCanvas.toBlob((serverImageBlob) => {
                            appBus.$emit('onScreenshotCapture', {
                                clientImageDataUrl: clientImageDataUrl,
                                serverImageBlob: serverImageBlob,
                                extent: extent,
                                eventId: eventId,
                            });
                            this.screenShotActive = false;
                        }, 'image/jpeg', 0.90);
                    });
                    this.map.$map.renderSync();
                });
            });
            this.map.$map.renderSync();
        },
        getMapCanvas(map) {

            let mapCanvas = document.createElement('canvas');
            let size = map.getSize();
            mapCanvas.width = size[0];
            mapCanvas.height = size[1];
            let mapContext = mapCanvas.getContext('2d');
            Array.prototype.forEach.call(
                document.querySelectorAll('.ol-layer canvas'),
                function (canvas) {
                    if (canvas.width > 0) {
                        let opacity = canvas.parentNode.style.opacity;
                        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                        let transform = canvas.style.transform;
                        // Get the transform parameters from the style's transform matrix
                        let matrix = transform
                            .match(/^matrix\(([^(]*)\)$/)[1]
                            .split(',')
                            .map(Number);
                        // Apply the transform to the export map context
                        CanvasRenderingContext2D.prototype.setTransform.apply(
                            mapContext,
                            matrix
                        );
                        mapContext.drawImage(canvas, 0, 0);
                    }
                }
            );
            return mapCanvas;
        },
        onMapMounted() {
            this.mapInitialized = true;
            let self = this;

            setTimeout(function () {
                self.setAutoFocus(false);
                self.setAutoFocus(true);
            }, 500);

        },
        onMapCreated() {

            this.map = this.$refs.map;
            this.$store.commit('map/setMap', this.map);
            let self = this;

            this.map.$map.on('pointerdrag', throttle(function () {
                self.setAutoFocus(false);
            }, 500));

            setTimeout(function () {
                document.querySelector('.vl-map .ol-viewport').onwheel = function () {
                    self.setAutoFocus(false);
                };
            }, 1000);
        },
        async onMapPointerMove({coordinate, pixel}) {

            if (this.hoverFeatures.length > 0) {
                this.hoverFeatures.forEach((item) => {
                    item.setProperties({hover: false});
                })
                this.hoverFeatures = [];
            }

            this.mouseCoordinate = coordinate;
            let features = await this.$refs.map.getFeaturesAtPixel(pixel, {hitTolerance: 1});
            if (features.length > 0) {

                let feature = features[0];
                let tooltip = feature.getProperties().tooltip;

                if (feature) {
                    this.hoverFeatures.push(feature);
                    feature.setProperties({hover: true})
                }

                if (tooltip !== undefined) {
                    this.setTooltip(tooltip, coordinate)
                } else {
                    const features = feature.get('features');
                    if (features) {
                        if (features.length > 1) {
                            this.tooltip = false;
                        } else {
                            let tooltip = features[0].getProperties().tooltip;
                            if (tooltip !== undefined) {
                                this.setTooltip(tooltip, coordinate)
                            } else {
                                this.tooltip = false;
                            }
                        }
                    }
                }

            } else {
                this.tooltip = false;
            }
        },
        setTooltip(tooltip, coordinate) {
            this.tooltip = {
                title: tooltip.title,
                content: tooltip.content,
                overview: tooltip.overview,
                comp: tooltip.comp,
                position: coordinate,
            };
        },
        async onContextMenu(event) {

            let pixel = this.$refs.map.$map.getEventPixel(event);

            let coordinates = null;
            let area = 'map';
            let target = 'map';
            let features = null;
            let contextMenu = null;
            let feature = null;
            let type = null;

            const hasFeatures = this.$refs.map.$map.hasFeatureAtPixel(pixel);

            if (hasFeatures) {
                features = await this.$refs.map.getFeaturesAtPixel(pixel, {hitTolerance: 1});
                feature = features[0];
                type = feature.getProperties().type;
                if (type) {
                    target = 'feature';
                    contextMenu = feature.getProperties().contextMenu;
                    const geometry = feature.getGeometry();
                    if (geometry.getType() === 'Point') {
                        coordinates = transform(geometry.getCoordinates(), this.viewProjection, this.dataProjection);    
                    } else {
                        coordinates = this.$refs.map.pointToDataProj(this.$refs.map.$map.getCoordinateFromPixel(pixel));
                    }
                } else {
                    features = null;
                    feature = null;
                    coordinates = this.$refs.map.pointToDataProj(this.$refs.map.$map.getCoordinateFromPixel(pixel));
                }
            } else {
                coordinates = this.$refs.map.pointToDataProj(this.$refs.map.$map.getCoordinateFromPixel(pixel));
            }

            if (coordinates) {
                const data = {
                    area: area,
                    target: target,
                    contextMenu: contextMenu,
                    type: type,
                    coordinates: coordinates,
                    feature: feature,
                };
                if (contextMenu) {
                    this.$refs[contextMenu].open(event, data);
                } else {
                    this.$refs.mapMenu.open(event, data);
                }
            }
        },
        async onClick(event) {

            document.activeElement.blur(); // fix close popups and context menus

            let pixel = event.pixel;
            let coordinates = event.coordinate;

            if (event.originalEvent.ctrlKey === true) {
                this.$store.commit('map/setOriginObject', {
                    type: "point",
                    title: "Origin point",
                    coordinates: coordinates
                });
            } else {
                let features = await this.$refs.map.getFeaturesAtPixel(pixel, {hitTolerance: 1});
                if (!isEmpty(features)) {
                    let feature = features[0];
                    let clusterFeatures = null;

                    switch (feature.getProperties().type) {
                        case 'eventTargetLink':
                        case 'eventTargetLinkArrow':
                            appBus.$emit(ON_SHOW_EVENT_LINK_DIALOG, feature.getId());
                            this.tooltip = false;
                            break;
                        case 'eventTarget':
                        case 'eventDestination':
                            this.setActiveEvent(feature.getId());
                            this.tooltip = false;
                            break;
                        case 'eventJob':
                            this.setActiveEvent(feature.getProperties().eventKey);
                            break;
                        case 'message':
                            document.querySelector('.messages #media-' + feature.getProperties().id).click();
                            this.tooltip = false;
                            break;
                        case undefined:
                            clusterFeatures = feature.get('features');
                            if (clusterFeatures) {
                                const extent = boundingExtent(
                                    clusterFeatures.map((r) => r.getGeometry().getCoordinates())
                                );
                                this.setAutoFocus(false);
                                this.$refs.mapView.$view.fit(extent, {
                                    size: this.$refs.map.$map.getSize(),
                                    duration: 500,
                                    maxZoom: 30,
                                    padding: [5, 5, 5, 5],
                                })
                            }
                            this.tooltip = false;
                            break;
                        default:
                    }
                }
            }
            this.clickCoordinate = coordinates;
        },
        fitToView(extend) {
            if (this.$refs.mapView.$view === undefined)
                return;

            this.$refs.mapView.$view.fit(extend, {
                size: this.$refs.map.$map.getSize(),
                duration: 500,
                maxZoom: this.zoomTo,
                padding: [20, 20, 20, 20],
                //easing:
            })
        },

    },
    watch: {
        extend(extend) {
            this.fitToView(extend)
        },
        zoom(level) {
            this.onZoom(level);
        },
        activeEvent() {
            this.$refs.mapMenu.$refs.contextMenu.close()
        }
    },
    beforeDestroy() {
        appBus.$off(ON_MAP_SCREEN_SHOT);
        appBus.$off(HIDE_TOOLTIP);
        appBus.$off('onSearchPoints');
        appBus.$off(ON_RESIZE);
    }

}
</script>
